<template>
  <div class="view-home winner-page">

    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb pl-0">
          <li class="breadcrumb-item"><a href="#">{{ $t('breadcrumb.main-page') }}</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.for-teachers') }}</li>
        </ol>
      </nav>
      <div class="page-title text-lg-left text-center">
        {{ $t('for-teachers.title') }}
      </div>
    </div>

    <div class="inform">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-3 text-center mb-3 mb-mb-0 text-lg-right ">
            <img src="/images/notification-big.svg" alt="">
          </div>
          <div class="col-lg-8 ">
            <div class="rounded-pill">
              {{ $t('for-teachers.info') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-join py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 mx-auto">
            <div class="card-heading mb-4">
              <p class="mb-0">{{ $t('for-teachers.info-title') }}</p>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="about-join-wrap clearfix">
                <div class="about-join-left col-lg-5 col-7 float-left pr-5">
                  <h6>{{ $t('for-teachers.info-left-title') }}</h6>
                  <p>{{ $t('for-teachers.info-left-desc') }}</p>
                  <ul v-html="$t('for-teachers.list')">

                  </ul>

                  <div class="url-wrapper">
                    <a target="_blank" href="https://youtu.be/eRqwvVqXRXY"
                       class="you-tube-logo">{{ $t('for-teachers.youtube-method') }}
                    </a>
                    <br>
                    <a target="_blank" href="https://www.facebook.com/groups/554514133095478/?ref=share "
                       class="facebook-logo">{{ $t('for-teachers.facebook') }}</a>
                  </div>
                </div>
                <div class="about-join-right col-lg-5 col-12  col-sm-7 float-right pl-5">
                  <h6>{{ $t('for-teachers.info-right-title') }}</h6>
                  <p>{{ $t('for-teachers.info-right-desc') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="action-step pt-0">
      <div class="section-title">
        {{ $t('for-teachers.about-testing-title') }}
      </div>
      <div class="container ">
        <div class="row">
          <div class="col-lg-9 m-auto">
            <ol class="step-list">
              <li class="step-item">
                <a href="/signup">
                  <img width="200" height="200" src="/images/main13.png"/>
                  <div class="title">
                    {{ $t('for-teachers.about-testing-1') }}
                  </div>
                </a>
              </li>
              <li class="step-item">
                <img width="200" height="200" src="/images/main3.png"/>
                <div class="title">
                  {{ $t('for-teachers.about-testing-2') }}
                </div>
              </li>

            </ol>
            <div class="row ">
              <div class="col-12 text-center text-lg-left">
                <!-- <a href="" class="btn btn-primary mr-3 mb-2">{{ $t('for-teachers.test-btn') }}</a> -->
                <router-link v-if="logged" to="/testing" class="btn btn-primary mr-3 mb-2" role="button">
                  {{ $t('for-teachers.test-btn') }}
                </router-link>
                <router-link v-else to="/signin" class="btn btn-primary mr-3 mb-2" href="#" role="button">
                  {{ $t('for-teachers.test-btn') }}
                </router-link>

                <a href="/regions" class="btn btn-primary mr-3 mb-2">{{ $t('for-teachers.address-btn') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
export default {
  data() {
    return {
      logged: false,
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
  }
}
</script>

<style>

</style>
